import { HttpClient } from '@angular/common/http';
import { ExportacaoUtil } from './../../util/exportacao.util';
import { ExportacaoUtilService } from './../../service/exportacao-util.service';
import { MensagemUtil } from './../../util/mensagem.util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Component, OnInit, Input } from '@angular/core';
import { PageNotificationService } from '@nuvem/primeng-components';

@Component({
  selector: 'app-sca-botoes-exportacao',
  templateUrl: './sca-botoes-exportacao.component.html',
  styleUrls: ['./sca-botoes-exportacao.component.css']
})
export class ScaBotoesExportacaoComponent implements OnInit {

  @Input() resourceUrl: string;
  @Input() sortField: string;
  @Input() sortOrder: string;
  @Input() datable: any;
  @Input() nomeRelatorio: string;
  @Input() filtroExportacao: any;

  @BlockUI() blockUI: NgBlockUI;

  url: any;

  tiposExportacao = [
    {
      label: 'PDF', icon: '', command: () => {
        this.exportar(ExportacaoUtilService.PDF);
      }
    },
    {
      label: 'EXCEL', icon: '', command: () => {
        this.exportar(ExportacaoUtilService.EXCEL);
      }
    },
    {
      label: 'IMPRIMIR', icon: '', command: () => {
        this.imprimir(ExportacaoUtilService.PDF);
      }
    }
  ];

  constructor(private pageNotificationService: PageNotificationService,
    private http: HttpClient) { }

  ngOnInit() {
  }

  exportar(tipoRelatorio: string) {

    this.blockUI.start(MensagemUtil.BLOCKUI_RELATORIO);
    ExportacaoUtilService.exportarRelatorio(tipoRelatorio, this.resourceUrl, this.http, this.sortField, this.sortOrder, this.getParams()).subscribe(downloadUrl => {
      ExportacaoUtil.download(downloadUrl, this.nomeRelatorio + ExportacaoUtilService.getExtension(tipoRelatorio));
      this.blockUI.stop();
    }, (error) => {
      this.pageNotificationService.addErrorMessage(MensagemUtil.MSG_ERRO_EXPORTAR_ARQUIVO);
      this.blockUI.stop();
    });
  }

  imprimir(tipoRelatorio: string) {
    this.blockUI.start(MensagemUtil.BLOCKUI_RELATORIO_IMPRESSAO);
    ExportacaoUtilService.exportarRelatorio(tipoRelatorio, this.resourceUrl, this.http, this.sortField, this.sortOrder, this.getParams()).subscribe(downloadUrl => {
      ExportacaoUtil.imprimir(downloadUrl);
      this.blockUI.stop();
    }, () => {
      this.pageNotificationService.addErrorMessage(MensagemUtil.MSG_ERRO_IMPRIMIR_ARQUIVO);
      this.blockUI.stop();
    });
  }

  private getParams(){
    let params = {};
    params['sort'] = this.datable.sortField;
    params['order'] = (this.datable.sortOrder === 1 ? 'ASC' : 'DESC');

    if (this.datable.filters) {
      for (const i in this.datable.filters) {
        params[i] = this.datable.filters[i].value;
      }
    }

    if (this.filtroExportacao) {
        for (const i in this.filtroExportacao) {
          if (typeof this.filtroExportacao[i].getDate === 'function') {
            params[i] = this.filtroExportacao[i].toISOString();
          } else {
            params[i] = this.filtroExportacao[i];
          }
        }
    }
    

    return params;
  }

}
