import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import { AuthorizationService, AbstractAuthorization } from '@nuvem/angular-base';
import {Usuario} from './usuario/usuario';


@Injectable()
export class AuthGuardRoutes implements CanActivate {
    constructor(private auth: AbstractAuthorization<Usuario>) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.auth.hasRole(route.data['role']);
    }
    hasRole(role: any) {
        return this.auth.hasRole(role);
    }
}
