import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
            <div class="footer">
            <div class="card clearfix">
                <span class="footer-text-left">
                    <span style="text-align:center"><a href="https://ibama.gov.br" target="_blank">IBAMA</a> - Instituto Brasileiro do Meio Ambiente e Recursos Naturais Renováveis</span>
                </span>
                
            <span class="footer-text-right">
                <span style="text-align:center">2019 - Todos os direitos reservados</span>
            </span>
            </div>
        </div>
    `
})
export class AppFooterComponent {

}
