export class CalendarUtil {

    public static getTraducao(): any {
        return {
            firstDayOfWeek: 0,
            dayNames: [ 'Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado' ],
            dayNamesShort: [ 'D', 'S', 'T', 'Q', 'Q', 'S', 'S' ],
            dayNamesMin: [ 'D', 'S', 'T', 'Q', 'Q', 'S', 'S' ],
            monthNames: [ 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro' ],
            monthNamesShort: [ 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez' ],
            today: 'Hoje',
            clear: 'Limpar'
        };
    }

}
