import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { map } from 'rxjs/operators';

export class ExportacaoUtilService {

    static CONTENT_TYPE_PDF = 'application/pdf';
    static CONTENT_TYPE_CSV = 'text/csv';
    static CONTENT_TYPE_EXCEL = 'application/vnd.ms-excel';
    static PDF = 'pdf';
    static EXCEL = 'xls';
    static CSV = 'csv';
    headers: Headers;
    options: RequestOptions;

    static getContentType(tipoRelatorio: string): any {
        if (tipoRelatorio === this.PDF) {
            return ExportacaoUtilService.CONTENT_TYPE_PDF;
        }
        if (tipoRelatorio === this.EXCEL) {
            return ExportacaoUtilService.CONTENT_TYPE_EXCEL;
        }
        if (tipoRelatorio === this.CSV) {
            return ExportacaoUtilService.CONTENT_TYPE_CSV;
        }
        return null;
    }

    private static getOptions(params:any) {
        const headers: HttpHeaders = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        const options = { headers: headers,  responseType: 'blob' as 'json', params:params};
        return options;
    }

    static exportarRelatorio(tipoRelatorio: string, resourceUrl: string, http: HttpClient, sort: string, order: string, params:any) {
        let sortField = sort ? '&sort=' + sort : '';
        let orderField = order ? '&order=' + order : '';
        return ExportacaoUtilService.gerar(
            `${resourceUrl}/exportacao/` + tipoRelatorio,
            ExportacaoUtilService.getContentType(tipoRelatorio),
            http,
            params
        );
    }

    static gerar(endpoint: string, tipo: string, http: HttpClient, params:any): any {
        return http.get<Blob>(endpoint, ExportacaoUtilService.getOptions(params))
            .pipe(
                map((res: any) => {
                    const file = new Blob([res], { type: tipo });
                    return URL.createObjectURL(file);
                })
            );
    }

    static getExtension(tipoRelatorio: string): string {
        if (tipoRelatorio === this.PDF) {
            return '.pdf';
        }
        if (tipoRelatorio === this.EXCEL) {
            return '.xls';
        }
        if (tipoRelatorio === this.CSV) {
            return '.csv';
        }
        return null;
    }
}