import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
    enableProdMode();
}

if (window.location.href.includes(environment.auth.loginSuccessRoute) ||
    null !== environment.auth.storage.getItem(environment.auth.userStorageIndex)) {
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.log(err));
} else {
    window.location.href = environment.auth.loginUrl;;
}


