export class PermissoesUtil {

    public static SISTEMA_ACESSAR = 'ROLE_MOD_CADASTRO_MANTER_SISTEMA_ACESSAR';
    public static SISTEMA_CADASTRAR = 'ROLE_MOD_CADASTRO_MANTER_SISTEMA_CADASTRAR';
    public static SISTEMA_EDITAR = 'ROLE_MOD_CADASTRO_MANTER_SISTEMA_EDITAR';
    public static SISTEMA_EXPORTAR = 'ROLE_MOD_CADASTRO_MANTER_SISTEMA_EXPORTAR';
    public static SISTEMA_REMOVER = 'ROLE_MOD_CADASTRO_MANTER_SISTEMA_REMOVER';
    public static SISTEMA_VISUALIZAR = 'ROLE_MOD_CADASTRO_MANTER_SISTEMA_VISUALIZAR';

    public static MODULO_ACESSAR = 'ROLE_MOD_CADASTRO_MANTER_MODULOS_ACESSAR';
    public static MODULO_CADASTRAR = 'ROLE_MOD_CADASTRO_MANTER_MODULOS_CADASTRAR';
    public static MODULO_EDITAR = 'ROLE_MOD_CADASTRO_MANTER_MODULOS_EDITAR';
    public static MODULO_EXPORTAR = 'ROLE_MOD_CADASTRO_MANTER_MODULOS_EXPORTAR';
    public static MODULO_REMOVER = 'ROLE_MOD_CADASTRO_MANTER_MODULOS_REMOVER';
    public static MODULO_VISUALIZAR = 'ROLE_MOD_CADASTRO_MANTER_MODULOS_VISUALIZAR';

    public static FUNCIONALIDADE_ACESSAR = 'ROLE_MOD_CADASTRO_MANTER_FUNCIONALIDADES_ACESSAR';
    public static FUNCIONALIDADE_CADASTRAR = 'ROLE_MOD_CADASTRO_MANTER_FUNCIONALIDADES_CADASTRAR';
    public static FUNCIONALIDADE_EDITAR = 'ROLE_MOD_CADASTRO_MANTER_FUNCIONALIDADES_EDITAR';
    public static FUNCIONALIDADE_EXPORTAR = 'ROLE_MOD_CADASTRO_MANTER_FUNCIONALIDADES_EXPORTAR';
    public static FUNCIONALIDADE_REMOVER = 'ROLE_MOD_CADASTRO_MANTER_FUNCIONALIDADES_REMOVER';
    public static FUNCIONALIDADE_VISUALIZAR = 'ROLE_MOD_CADASTRO_MANTER_FUNCIONALIDADES_VISUALIZAR';

    public static ACAO_ACESSAR = 'ROLE_MOD_CADASTRO_MANTER_ACOES_ACESSAR';
    public static ACAO_CADASTRAR = 'ROLE_MOD_CADASTRO_MANTER_ACOES_CADASTRAR';
    public static ACAO_EDITAR = 'ROLE_MOD_CADASTRO_MANTER_ACOES_EDITAR';
    public static ACAO_EXPORTAR = 'ROLE_MOD_CADASTRO_MANTER_ACOES_EXPORTAR';
    public static ACAO_REMOVER = 'ROLE_MOD_CADASTRO_MANTER_ACOES_REMOVER';
    public static ACAO_VISUALIZAR = 'ROLE_MOD_CADASTRO_MANTER_ACOES_VISUALIZAR';

    public static PERFIL_ACESSAR = 'ROLE_MOD_CADASTRO_MANTER_PERFIS_ACESSAR';
    public static PERFIL_CADASTRAR = 'ROLE_MOD_CADASTRO_MANTER_PERFIS_CADASTRAR';
    public static PERFIL_EDITAR = 'ROLE_MOD_CADASTRO_MANTER_PERFIS_EDITAR';
    public static PERFIL_EXPORTAR = 'ROLE_MOD_CADASTRO_MANTER_PERFIS_EXPORTAR';
    public static PERFIL_REMOVER = 'ROLE_MOD_CADASTRO_MANTER_PERFIS_REMOVER';
    public static PERFIL_VISUALIZAR = 'ROLE_MOD_CADASTRO_MANTER_PERFIS_VISUALIZAR';

    public static ATRIBUIR_PERFIL_ACESSAR = 'ROLE_MOD_GERENCIAMENTO_ATRIBUIR_PERFIL_ACESSAR';
    public static ATRIBUIR_PERFIL_CADASTRAR = 'ROLE_MOD_GERENCIAMENTO_ATRIBUIR_PERFIL_CADASTRAR';
    public static ATRIBUIR_PERFIL_EDITAR = 'ROLE_MOD_GERENCIAMENTO_ATRIBUIR_PERFIL_EDITAR';
    public static ATRIBUIR_PERFIL_EXPORTAR = 'ROLE_MOD_GERENCIAMENTO_ATRIBUIR_PERFIL_EXPORTAR';
    public static ATRIBUIR_PERFIL_REMOVER = 'ROLE_MOD_GERENCIAMENTO_ATRIBUIR_PERFIL_REMOVER';
    public static ATRIBUIR_PERFIL_VISUALIZAR = 'ROLE_MOD_GERENCIAMENTO_ATRIBUIR_PERFIL_VISUALIZAR';

    public static GESTAO_UNIDADES_ACESSAR = 'ROLE_MOD_CADASTRO_GESTAO_UNIDADES_ACESSAR';
    public static GESTAO_UNIDADES_CADASTRAR = 'ROLE_MOD_CADASTRO_GESTAO_UNIDADES_CADASTRAR';
    public static GESTAO_UNIDADES_EDITAR = 'ROLE_MOD_CADASTRO_GESTAO_UNIDADES_EDITAR';
    public static GESTAO_UNIDADES_EXPORTAR = 'ROLE_MOD_CADASTRO_GESTAO_UNIDADES_EXPORTAR';
    public static GESTAO_UNIDADES_REMOVER = 'ROLE_MOD_CADASTRO_GESTAO_UNIDADES_REMOVER';
    public static GESTAO_UNIDADES_VISUALIZAR = 'ROLE_MOD_CADASTRO_GESTAO_UNIDADES_VISUALIZAR';

    public static SISTEMAS_INTEGRADOS_ACESSAR = 'ROLE_MOD_CADASTRO_MANTER_SISTEMAS_INTEGRADOS_ACESSAR';
    public static SISTEMAS_INTEGRADOS_CADASTRAR = 'ROLE_MOD_CADASTRO_MANTER_SISTEMAS_INTEGRADOS_CADASTRAR';
    public static SISTEMAS_INTEGRADOS_EXPORTAR = 'ROLE_MOD_CADASTRO_MANTER_SISTEMAS_INTEGRADOS_EXPORTAR';
    public static SISTEMAS_INTEGRADOS_REMOVER = 'ROLE_MOD_CADASTRO_MANTER_SISTEMAS_INTEGRADOS_REMOVER';
    public static SISTEMAS_INTEGRADOS_EDITAR = 'ROLE_MOD_CADASTRO_MANTER_SISTEMAS_INTEGRADOS_EDITAR';
    public static SISTEMAS_INTEGRADOS_VISUALIZAR = 'ROLE_MOD_CADASTRO_MANTER_SISTEMAS_INTEGRADOS_VISUALIZAR';

    public static CONTROLE_ACESSO_ACESSAR = 'ROLE_MOD_CADASTRO_MANTER_CONTROLE_ACESSO_ACESSAR';
    public static CONTROLE_ACESSO_CADASTRAR = 'ROLE_MOD_CADASTRO_MANTER_CONTROLE_ACESSO_CADASTRAR';
    public static CONTROLE_ACESSO_EXPORTAR = 'ROLE_MOD_CADASTRO_MANTER_CONTROLE_ACESSO_EXPORTAR';
    public static CONTROLE_ACESSO_REMOVER = 'ROLE_MOD_CADASTRO_MANTER_CONTROLE_ACESSO_REMOVER';
    public static CONTROLE_ACESSO_EDITAR = 'ROLE_MOD_CADASTRO_MANTER_CONTROLE_ACESSO_EDITAR';
    public static CONTROLE_ACESSO_VISUALIZAR = 'ROLE_MOD_CADASTRO_MANTER_CONTROLE_ACESSO_VISUALIZAR';


    public static  RELATORIOS_LOG_ACESSO_API_ACESSAR = "ROLE_MOD_RELATORIO_API_LIGHT_LOG_ACESSO_API_ACESSAR";
    public static  RELATORIOS_LOG_ACESSO_API_EXPORTAR = "ROLE_MOD_RELATORIO_API_LIGHT_LOG_ACESSO_API_EXPORTAR";

    

    public static PERMISSOES_BASE = [PermissoesUtil.CONTROLE_ACESSO_ACESSAR, PermissoesUtil.MODULO_ACESSAR, PermissoesUtil.PERFIL_ACESSAR, PermissoesUtil.ATRIBUIR_PERFIL_ACESSAR, PermissoesUtil.GESTAO_UNIDADES_ACESSAR, PermissoesUtil.SISTEMAS_INTEGRADOS_ACESSAR, PermissoesUtil.CONTROLE_ACESSO_ACESSAR, PermissoesUtil.FUNCIONALIDADE_ACESSAR, PermissoesUtil.ACAO_ACESSAR]
}
