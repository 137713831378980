import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
import { AppComponent } from './app.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {

    version: string = environment.VERSION;

    constructor(public app: AppComponent) {}

}
