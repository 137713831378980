import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@nuvem/angular-base';


@Component({
  selector: 'app-login',
  template: ''
})
export class LoginComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.redirect();
  }
}
