import { PageNotificationService } from '@nuvem/primeng-components';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class SuccessInterceptor implements HttpInterceptor {

    constructor(private pageNotificationService: PageNotificationService){

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const msg = event.headers.get('x-scaserviceapp-alert');
                    if(msg){
                        this.pageNotificationService.addSuccessMessage(msg);
                    }
                }
                return event;
            }));
    }

}