export class MensagemUtil {

    //Mensagens genéricas
    public static BLOCKUI_CARREGANDO = 'Carregando...';
    public static BLOCKUI_EXCLUINDO = 'Excluindo...';
    public static BLOCKUI_RELATORIO ='Gerando relatório...'
    public static BLOCKUI_RELATORIO_IMPRESSAO ='Imprimindo relatório...'
    public static BLOCKUI_SALVANDO = 'Salvando...';
    public static DATATABLE_EMPTY_RECORDS = 'Nenhum registro encontrado';
    public static MSG_CONFIRMACAO_REMOCAO = 'Deseja realmente excluir o registro selecionado?';
    public static MSG_ERROR_CAMPOS_OBRIGATORIOS = 'Campo(s) obrigatório(s) não preenchido(s)';
    public static MSG_ERROR_IP_INVALIDO = 'IP inválido.';
    public static MSG_ERRO_EXPORTAR_ARQUIVO = 'Erro ao exportar arquivo';
    public static MSG_ERRO_IMPRIMIR_ARQUIVO = 'Erro ao imprimir relatŕoio';
    public static MSG_ERRO_DUAS_UNIDADES_PAI = 'Não é permitido adicionar mais de uma unidade sem unidade vinculada';
    public static MSG_CPF_CADASTRO_NAO_REGISTRADO = 'Não é possível salvar perfil com CPF não registrado';
    public static MSG_CPF_CADASTRO_VAZIO = 'Não é possível salvar perfil com CPF vazio';
    public static MSG_BUSCA_SEM_RESULTADOS = 'A busca feita não encontrou registros para os filtros informados.';
    public static MSG_BUSCA_UNIDADES_FILTROS = 'Selecionar uma opção de Unidade da Federação e no mínimo 3 caracteres no nome da unidade.';

    public static TITULO_REMOCAO = 'Remover Registro';
    public static PESSOA_NAO_ENCONTRADA = 'Nenhuma Pessoa encontrada!';

    //Tipos de erro
    public static TYPE_ERROR = 'Erro!';

    //Validações
    public static ALTERAR_CHAVE_SISTEMA_INTEGRADO = 'A alteração de chave implicará em necessidade de alteração do sistema integrado. Confirma operação?';
    public static DESEJA_EXCLUIR_REGISTRO_SELECIONADO = 'Deseja realmente excluir o registro selecionado?';

    public static  SUCESSO_CADASTRO_CONTROLE_ACESSO = 'Controle de Acesso por Atividade cadastrado com sucesso!';
    public static  SUCESSO_EDICAO_CONTROLE_ACESSO = 'Controle de Acesso por Atividade alterado com sucesso!';

    public static DATA_FUTURA = 'Data inválida. Não é possível fazer a consulta com uma data futura.';
    public static DATA_FIM = 'Data inválida. A Data de Início do Período deve ser menor que a Data Fim.';
    public static PERIODO_INVALIDO = 'Período inválido. É necessário o preenchimento da Data de Início e Fim.';

}
