import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {DiarioErrosComponent} from './diario-erros/diario-erros.component';
import {AuthGuard} from '@nuvem/angular-base';
import {LoginSuccessComponent} from './login-success/login-success.component';
import {LogoutComponent} from './logout/logout.component';

export const routes: Routes = [
    { path: 'diario-erros', component: DiarioErrosComponent,
        canActivate: [AuthGuard] },
    {
        path: 'cadastro', loadChildren: './cadastro/cadastro.module#CadastroModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'administracao', loadChildren: './gerenciamento/gerenciamento.module#GerenciamentoModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'relatorios', loadChildren: './relatorios/relatorios.module#RelatoriosModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'login-success',
        component: LoginSuccessComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
        canActivate: [AuthGuard]
      }
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
