import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AbstractLogin} from '@nuvem/angular-base';
import {Usuario} from '../usuario/usuario';

@Component({
  selector: 'app-login-success',
  template: ''
})
export class LoginSuccessComponent implements OnInit {

    constructor(private loginService: AbstractLogin<Usuario>, private router: Router) {}

    ngOnInit() {
        this.loginService.setUserDetails().subscribe(value => {
            this.router.navigate(['']).then(() => window.location.reload(true));
        });
    }
}
