import {
    Injector,
    Pipe,
    PipeTransform
} from '@angular/core';


@Pipe({
  name: 'statusPipe'
})
export class StatusPipe implements PipeTransform {

    public constructor(private injector: Injector) {
    }

    transform(value: any, pipeArgs: any[]): any {
        if(value == 'A'){
          return 'Ativo';
        }
        return 'Inativo';
    }
}