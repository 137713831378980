import { Component, OnInit } from '@angular/core';
import { AbstractLogin } from '@nuvem/angular-base';

import { Usuario } from '../usuario/usuario';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(private login: AbstractLogin<Usuario>) {}

  ngOnInit() {
    this.login.logout();
    this.login.redirect();
  }
} 